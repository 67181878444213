import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const HeroImage = () => {
  return (
    <h1>
      <div class="relative md:block text-white">
        <StaticImage src="../images/contact/contact_top.png" />

        <div
          class="absolute top-0 text-left"
          style={{ marginTop: "8.5%", marginLeft: "10%" }}
        >
          <div class="text-sm md:text-2xl lg:text-3xl font-bold">お問合せ</div>
          <div class="text-xs md:text-base lg:text-lg font-semibold">
            CONTACT
          </div>
        </div>
      </div>
    </h1>
  );
};

const ContactPage = () => {
  return (
    <Layout>
      <div class="container mx-auto text-center">
        <HeroImage />
        <div class="w-full">
          <div class="w-11/12 md:w-10/12 mx-auto text-center text-sm md:text-base md:px-2 mb-12 hidden">
            <h2 class="py-8 text-center w-9/12 md:w-1/4 mx-auto">
              <div className="text-darkblue text-2xl font-semibold">
                お問合せ
              </div>
              <div class="text-secondary text-lg">CONTACT</div>
            </h2>
            <p>
              以下のフォームに必要事項をご記入の上、「送信」ボタンを押してください。
            </p>
          </div>
          <div className="bg-contact_bg mb-12">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSeFzBEExjXe5VQzGNKsR19YjCE0bWUT9UlErx1j2vCEHtuOdw/viewform?embedded=true"
              width="100%"
              height="1700"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Contact form"
            >
              読み込んでいます…
            </iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
